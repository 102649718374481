<template>
  <div :class="$style.topbar">
    <div class="mr-auto">
    </div>
    <!-- <a-input
        style="width: 100% !important; border: 0px solid #FFF !important; padding: 7px; font-weight: bold; font-size: 18px; color: #000;"
        placeholder="Search..."
      >
        <template #prefix>
          <span>
            <search-outlined class="mr-3" style="color: #FE5002; font-size: 18px" />
          </span>
        </template>
</a-input> -->
    <div class="float-right">
      <vb-user-menu />
    </div>
  </div>
</template>

<script>
// import VbSearch from './Search'
import VbUserMenu from './UserMenu'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  components: {
    VbUserMenu,
  },
  setup() {
    const store = useStore()
    const user = computed(() => store.getters['user/user'])

    return {
      user,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
