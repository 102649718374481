export const getMenuData = [
  // VB:REPLACE-START:MENU-CONFIG
  // {
  //   title: 'Dasbor',
  //   key: '__dasbor',
  //   url: '/dasbor',
  //   icon: 'fe fe-home',
  // },
  {
    title: 'Dashboard',
    key: '__dashboard',
    url: '/dashboard',
    // roles: ['admin'],
    icon: 'fe fe-grid',
  },
  {
    title: 'Assets',
    key: '__assets',
    url: '/assets',
    // roles: ['admin'],
    icon: 'fe fe-box',
  },
  {
    title: 'EOS Monitoring',
    key: '_eos-monitoring',
    url: '/eos-monitoring',
    // roles: ['admin'],
    icon: 'fe fe-monitor',
  },
  {
    category: true,
    title: 'RECEPTIONIST',
    key: 'q7r3hr',
  },
  {
    title: 'Entrance System',
    key: '__entrance',
    url: '/receptionist',
    // roles: ['admin'],
    icon: 'fe fe-log-in',
  },
  {
    category: true,
    title: 'SITE MANAGER',
    key: 'q7r3hr',
  },
  {
    title: 'Rollout',
    key: '__rollout',
    url: '/rollout',
    // roles: ['admin'],
    icon: 'fe fe-book',
  },
  {
    title: 'ATS',
    key: '__ats',
    url: '/ats',
    // roles: ['admin'],
    icon: 'fe fe-alert-circle',
  },
  {
    category: true,
    title: 'SUPER ADMIN',
    key: 'q7r3hr',
  },
  {
    title: 'Patients',
    key: '__users',
    url: '/patients',
    // roles: ['admin'],
    icon: 'fe fe-list',
  },
  {
    title: 'Customers',
    key: '__customers',
    url: '/customers',
    // roles: ['admin'],
    icon: 'fe fe-users',
  },
  {
    title: 'Vendors',
    key: '__vendors',
    url: '/vendors',
    // roles: ['admin'],
    icon: 'fe fe-box',
  },
  {
    category: true,
    title: 'Helpdesk',
    key: 'q7r3hr',
  },
  {
    category: true,
    title: 'EOS',
    key: 'q7r3hr',
  },
  {
    title: 'Monitoring',
    key: '__monitoring',
    url: '/monitoring',
    // roles: ['admin'],
    icon: 'fe fe-video',
  },
  {
    category: true,
    title: 'Change',
    key: 'q7r3hr',
  },
  {
    title: 'Change Request',
    key: '__change_request',
    url: '/request',
    // roles: ['admin'],
    icon: 'fe fe-layers',
  },
  {
    title: 'Troubleshoot',
    key: '__troubleshoot',
    url: '/monitoring/troubleshoot',
    icon: 'fe fe-settings',
  },
  {
    title: 'Addition',
    key: '__activeMonitoring',
    url: '/monitoring/addition',
    icon: 'fe fe-plus-square',
  },
  {
    title: 'Dismantle',
    key: '__dismantle',
    url: '/monitoring/dismantle',
    icon: 'fe fe-minus-square',
  },
  {
    title: 'Testing',
    key: '__testing',
    url: '/monitoring/testing',
    icon: 'fe fe-check-square',
  },
  {
    title: 'Receptionist',
    key: '__receptionist',
    url: '/receptionist',
    // roles: ['admin'],
    icon: 'fe fe-user-check',
  },

  {
    title: 'Helpdesk',
    key: '__helpdesk',
    url: '/helpdesk',
    // roles: ['admin'],
    icon: 'fe fe-mail',
  },
  {
    title: 'Base',
    key: '__base',
    url: '/base',
    // roles: ['admin'],
    icon: 'fe fe-map',
    children: [
      {
        title: 'Lokasi',
        key: '__lokasi',
        url: '/base/lokasi',
        // icon: 'fe fe-map',
      },
      {
        title: 'Ruangan',
        key: '__ruangan',
        url: '/base/ruangan',
        // icon: 'fe fe-box',
      },
      {
        title: 'Manufaktur',
        key: '__manufaktur',
        url: '/base/manufaktur',
        // icon: 'fe fe-box',
      },
    ],
  },
  {
    category: true,
    title: 'Master Data',
    // roles: ['admin'],
    key: '2t2ghm',
  },
  {
    title: 'Facilities',
    key: '__facilities',
    url: '/facilities',
    // roles: ['admin'],
    icon: 'fe fe-printer',
    children: [
      {
        title: 'Pintu Akses',
        key: '__access_door',
        url: '/facilities/access_door',
        // icon: 'fe fe-map',
      },
      {
        title: 'CCTV',
        key: '__cctv',
        url: '/facilities/cctv',
        // icon: 'fe fe-map',
      },
      {
        title: 'Precision AC',
        key: '__precision_ac',
        url: '/facilities/precision_ac',
        // icon: 'fe fe-map',
      },
      {
        title: 'Standard AC',
        key: '__standard_ac',
        url: '/facilities/standard_ac',
        // icon: 'fe fe-map',
      },
      {
        title: 'Electrical Panel',
        key: '__electiracal_panel',
        url: '/facilities/electrical_panel',
        // icon: 'fe fe-map',
      },
      {
        title: 'Env. Monitoring',
        key: '__env_monitoring',
        url: '/facilities/env_monitoring',
        // icon: 'fe fe-map',
      },
      {
        title: 'Fire Suppresion',
        key: '__fire_suppresion',
        url: '/facilities/fire_suppresion',
        // icon: 'fe fe-map',
      },
      {
        title: 'Fire Piping',
        key: '__fire_piping',
        url: '/facilities/fire_piping',
        // icon: 'fe fe-map',
      },
      {
        title: 'Fire Extinguisher',
        key: '__fire_extinguisher',
        url: '/facilities/fire_extinguisher',
        // icon: 'fe fe-map',
      },
      {
        title: 'Generator Set',
        key: '__generator_set',
        url: '/facilities/generator_set',
        // icon: 'fe fe-map',
      },
      {
        title: 'UPS',
        key: '__ups',
        url: '/facilities/ups',
        // icon: 'fe fe-map',
      },
      {
        title: 'Battery',
        key: '__battery',
        url: '/facilities/battery',
        // icon: 'fe fe-map',
      },
    ],
  },
  {
    title: 'Active Device',
    key: '__activeDevice',
    url: '/active',
    // roles: ['admin'],
    icon: 'fe fe-cpu',
    children: [
      {
        title: 'Access Point',
        key: '__access_point',
        url: '/active/access_point',
        // icon: 'fe fe-map',
      },
      {
        title: 'Firewall',
        key: '__firewall',
        url: '/active/firewall',
        // icon: 'fe fe-map',
      },
      {
        title: 'Router',
        key: '__router',
        url: '/active/router',
        // icon: 'fe fe-map',
      },
      {
        title: 'Server',
        key: '__server',
        url: '/active/server',
        // icon: 'fe fe-map',
      },
      {
        title: 'Switch',
        key: '__switch',
        url: '/active/switch',
        // icon: 'fe fe-map',
      },
      {
        title: 'Storage',
        key: '__storage',
        url: '/active/storage',
        // icon: 'fe fe-map',
      },
    ],
  },
  {
    title: 'Passive Device',
    key: '__passiveDevice',
    url: '/passive',
    // roles: ['admin'],
    icon: 'fe fe-speaker',
    children: [
      {
        title: 'UTP Cable',
        key: '__utp_cable',
        url: '/passive/utp_cable',
        // icon: 'fe fe-map',
      },
      {
        title: 'UTP Patch Panel',
        key: '__utp_patch_panel',
        url: '/passive/utp_patch_panel',
        // icon: 'fe fe-map',
      },
      {
        title: 'FO Cable',
        key: '__fo_cable',
        url: '/passive/fo_cable',
        // icon: 'fe fe-map',
      },
      {
        title: 'FO Patch Panel',
        key: '__fo_patch_panel',
        url: '/passive/fo_patch_panel',
        // icon: 'fe fe-map',
      },
      {
        title: 'Rack',
        key: '__rack',
        url: '/passive/rack',
        // icon: 'fe fe-map',
      },
    ],
  },
  {
    title: 'Software',
    key: '__software',
    url: '/software',
    // roles: ['admin'],
    icon: 'fe fe-hard-drive',
  },
  {
    title: 'Infrastruktur',
    key: '__infrastruktur',
    url: '/infrastruktur',
    // roles: ['admin'],
    icon: 'fe fe-home',
  },
  {
    category: true,
    title: 'Menu Pendukung',
    // roles: ['admin'],
    key: '2t2ghm',
  },
  {
    title: 'Manajemen Akun',
    key: '__akun',
    url: '/akun',
    // roles: ['admin'],
    icon: 'fe fe-users',
  },

  // VB:REPLACE-END:MENU-CONFIG
]
