<template>
  <a-menu-item :key="menuInfo.key" v-bind="$props">
    <router-link v-if="menuInfo.url" :to="menuInfo.url">
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count && menuInfo.title === 'Patients'" class="badge badge-warning ml-2">{{ menuInfo.count
        }}</span>
      <i :class="[styles.icon, menuInfo.icon]" :style="{ 'padding-left': settings.isMenuCollapsed ? '0.3em' : null }" />
      <!-- <i v-if="menuInfo.title !== 'Dashboard'" :class="[styles.icon, menuInfo.icon]"
        :style="{ 'padding-left': settings.isMenuCollapsed ? '0.3em' : null }" />
      <div :style="{ 'text-align': settings.isMenuCollapsed ? 'center' : null }" v-else>
        <img :class="[styles.icon]" src="../../../../assets/img/dashboard-icon.png" alt="" />
      </div> -->
    </router-link>
    <span v-if="!menuInfo.url" :to="menuInfo.url">
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span>
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]"
        :style="{ 'padding-left': settings.isMenuCollapsed ? '0.3em' : null }" />
    </span>
  </a-menu-item>
</template>

<script>
import { Menu } from 'ant-design-vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'SubMenu',
  props: {
    ...Menu.Item.props,
    menuInfo: {
      type: Object,
      default: () => { },
    },
    styles: {
      type: Object,
      default: () => { },
    },
  },
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
}
</script>
