<template>
  <div class="d-flex align-items-center">
    <a-dropdown :trigger="['click']" placement="bottomLeft">
      <div :class="$style.dropdown">
        <a-avatar shape="circle" size="large" :class="$style.avatar" class="mr-3">
          <template #icon>
            <UserOutlined />
          </template>
        </a-avatar>
        <span class="text-decoration-none mr-2" style="color: #3C1053;">Admin Anita</span>
      </div>
      <template #overlay>
        <a-menu>
          <a-menu-item @click="logout">
            <!-- TO DO DUMMY FUNCTION, UNCOMMENT LINE BELOW -->
            <!-- <a class="text-danger" @click="logout"> -->
            <a class="text-danger">
              <i class="fe fe-log-out" />
              Keluar
            </a>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
import { UserOutlined, BellOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {
    UserOutlined,
    // BellOutlined,
  },
  setup() {
    const store = useStore()
    const user = computed(() => store.getters['user/user'])

    const logout = () => {
      // TO DO DUMMY FUNCTION, UNCOMMENT LINE BELOW
      store.dispatch('user/LOGOUT')
      const router = useRouter()
      router.push('/masuk')
    }

    return {
      user,
      logout,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';

.notification-menu {
  max-width: 300px;
  padding: 10px;
  background-color: #f9f9f9;
}

.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.notification-title {
  font-weight: 600;
  color: #333;
}

.text-danger {
  color: #f5222d;
}

.notification-description {
  font-size: 14px;
  color: #888;
  margin: 2px 0 0;
}

.notification-time {
  font-size: 12px;
  color: #999;
}
</style>
